<template>
  <div>
    <div class="generateReportForInsuranceOuterContainer">

      <div id="my-node2" class="reportForInsuranceContainer" v-if="volunteerData">
        <img :src="require('@/assets/images/content/business_letters/insurance.jpg')" class="bgImg">

        <div class="reportDateContainer">
          <p class="month">{{ moment(startDate).format('MMMM') }}</p>
          <p class="yearLabel">leta</p>
          <p class="year">{{ moment(startDate).format('YYYY') }}</p>
        </div>

        <div class="unitInfoContainer">
          <div class="unitInfoLabel">PODATKI ZA ENOTO:</div>
          <div class="unitInfo">{{ volunteerData[0].data[0].unit.name }}
            ({{ volunteerData[0].data[0].unit.coveredMunicipalities }})
          </div>
        </div>

        <div class="volunteersContainer">

          <table class="table" style="width: 100%; text-align: left">
            <thead>
            <tr>
              <th style="width: 20px;" class="p-0">#</th>
              <th class="p-0">Prostovoljec</th>
              <th class="p-0" style="width: 350px;">Naslov bivališča</th>
              <th style="width: 100px;" class="p-0">Datum rojstva</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(volunteer, index) in volunteerData">
              <td style="width: 20px;">{{ index + 1 }}</td>
              <td>{{ volunteer.volunteerData.firstName }} {{ volunteer.volunteerData.lastName }}</td>
              <td>{{ volunteer.volunteerData.address.quickSearch.replace(', Slovenija', '') }}</td>
              <td style="width: 100px;">{{ moment(volunteer.volunteerData.birthday).format('DD. MM. YYYY') }}</td>
            </tr>

            </tbody>
          </table>


        </div>

        <div class="date">Sežana, {{ moment().format('DD. MM. YYYY') }}</div>

      </div>

    </div>

  </div>

</template>

<script>
import Vue from "vue";
import VueQrcode from "vue-qrcode";

export default {
  name: 'StatisticsOnVolunteersInsurance',

  components: {
    VueQrcode
  },
  data() {
    return {
      volunteerData: null,
      startDate: null,
      endDate: null,
      unitId: null,
    }
  },

  computed: {},

  async mounted() {
    const _this = this;


    if (this.$route.query.jwt) {
      Vue.prototype.$http.interceptors.request.use(
        config => {
          const token = this.$route.query.jwt;
          if (token) {
            if (config.url.includes('https://api.sopotniki.org/')) {
              config.headers.common["Authorization"] = 'Bearer ' + token;
            } else {
              config.headers.common["Authorization"] = token;
            }
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );

    }

    //_this.donorData = _this.$route.params.donorData;
    _this.startDate = Number(_this.$route.query.startDate) || _this.$route.params.startDate;
    _this.endDate = Number(_this.$route.query.endDate) || _this.$route.params.endDate;
    _this.unitId = Number(_this.$route.query.unitId) || _this.$route.params.unitId;
    await _this.getDailyReports();
  },
  methods: {
    getDailyReports() {
      const _this = this;
      _this.$vs.loading();

      const unit = _this.unitId;
      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${unit}&startDate=${_this.moment(_this.startDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.endDate).endOf('month').valueOf()}`;

      Vue.prototype.$http.get(url)
        .then((res) => {
          console.log("_this.dailyReportsData", res.data);
          const dailyReportsData = _.sortBy(res.data.data, ['date']);


          let volunteerData = _.chain(dailyReportsData)
            .groupBy('volunteer.id')
            .map((value, key) => {
              return {volunteer: key, volunteerData: value[0].volunteer, data: value}
            })
            .filter(el => el.volunteerData.firstName !== 'Ni' && el.volunteerData.lastName !== 'Voženj')
            .value();

          _this.volunteerData = _.sortBy(volunteerData, [function (o) {
            return o.data.length;
          }]).reverse();

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
        });
    },
  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}

@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateReportForInsuranceOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .reportForInsuranceContainer {
    position: relative;
    overflow: hidden;
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 18% 20% 15% 7% !important;
    display: block !important;
    color: black !important;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 0px;
    }

    .reportDateContainer {
      position: absolute;
      left: 387px;
      top: 130px;

      .month {
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
        display: inline-block;
      }

      .yearLabel {
        font-size: 18px;
        padding: 0px 20px;
        color: #000000;
        text-transform: uppercase;
        display: inline-block;
      }

      .year {
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
        display: inline-block;
      }

    }

    .unitInfoContainer {
      position: absolute;
      left: 74px;
      top: 350px;

      .unitInfoLabel {
        font-size: 14px;
        line-height: 30px;
        padding: 0px 5px 0px 20px;
        color: #000000;
        text-transform: uppercase;
        display: inline-block;
      }

      .unitInfo {
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        text-transform: uppercase;
        font-weight: bold;
        display: inline-block;
      }
    }

    .volunteersContainer {
      position: absolute;
      left: 95px;
      top: 385px;
      font-size: 13px;
      color: #000000;
      width: 80%;

    }

    .date {
      position: absolute;
      left: 210px;
      top: 912px;
      font-size: 16px;
      line-height: 30px;
      color: #000;

    }
  }
}
</style>
